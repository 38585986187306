<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Visit #</th>
            <th class="simple-table-th">Title</th>
            <!--   <th class="simple-table-th">Customer</th> -->
            <th class="simple-table-th">Appointment Date</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Assign To</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr
                  class="alternate-listing-row"
                  :key="index"
                  v-on:click="detailvisit(row?.visit_id)"
                >
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <Barcode
                        small
                        :barcode="row?.trip_barcode"
                        :id="row?.id"
                        :route="'visit.detail'"
                      ></Barcode>
                    </p>
                  </td>
                  <td>
                    <div class="m-0 custom-nowrap-ellipsis d-flex">
                      <template v-if="row?.visit_title">
                        <div class="my-auto ml-1">
                          <read-more
                            class="custom-read-more font-size-16 pb-1"
                            more-str="read more"
                            :text="row?.visit_title || '<em>No Title</em>'"
                            link="#"
                            less-str="read less"
                            :max-chars="35"
                          >
                          </read-more>
                        </div>
                      </template>
                      <em class="text-muted" v-else> no title </em>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Start : </b>
                        {{ formatDateTime(row?.visit_started_at) }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>End : </b>
                        {{ formatDateTime(row?.visit_finished_at) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <CustomStatus
                        small
                        :status="row?.visit_status"
                        endpoint="visit/status"
                      ></CustomStatus>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 max-content-width text-truncate font-weight-700 text-capitalize"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.visit_engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.visit_engineers) }}
                      </p>
                    </div>
                    <div>
                      <v-chip
                        v-if="
                          row.visit_engineers && row.visit_engineers.length > 0
                        "
                        label
                        color="orange"
                        class="p-3 mr-5"
                        text-color="white"
                        small
                        >Assign</v-chip
                      >
                      <v-chip
                        v-if="
                          row.visit_engineers && row.visit_engineers.length == 0
                        "
                        label
                        color="#24326d"
                        class="p-3 mr-5"
                        text-color="white"
                        small
                        >Unassign</v-chip
                      >
                    </div>
                  </td>
                  <td class="simple-table-td">
                    <TableActivity :data="row">
                      <template v-slot:date_time>
                        <template v-if="getDateCountAll(row?.added_at)">{{
                          row?.created_at
                        }}</template>
                        <template v-else>{{
                          formatDateTime(row?.added_at)
                        }}</template>
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDate(row?.added_at) }}
                        {{ formatTime(row?.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no visit at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
/*   import moment from "moment"; */
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
      },
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
    equipmentId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    // equipmentId(param) {
    //   this.equipment.customer = param;
    // },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.get_all_equipment();
          }
        }
      },
    },
  },
  components: {
    CustomStatus,
    TableActivity,
    /*   Dialog, */

    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    detailWarrnty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    detailvisit(param) {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: {
            id: param,
          },
        })
      );
    },

    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.get_all_equipment();
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned.";
      } else {
        return display_name.join(", ");
      }
    },
    get_all_equipment() {
      const _this = this;

      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: `equipment/visits/${_this.equipmentId}`,
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    //_this.get_options();

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_equipment();
    });

    /*   _this.$nextTick(() => {
      _this.equipment.customer = _this.equipmentId;
    }); */
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
