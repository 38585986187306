<template>
  <Dialog :dialog="approveDialog" :dialog-width="600">
    <template v-slot:title> {{ statusText }}</template>
    <template v-slot:body>
      <v-form
        ref="approveButtonForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="approveSubmit"
      >
        <v-row class="delete-dialog">
          <v-col md="1" class="py-0 text-left my-auto">
            <v-icon color="green" size="40">mdi-checkbox-marked-circle</v-icon>
            <span class="svg-icon svg-icon-lg delete-confirm-icon" v-if="false">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="11" class="py-0 my-auto">
            <p class="btx-p m-0" style="font-size: 14px">
              Are you sure {{ statusText }}
            </p>
          </v-col>
          <v-col md="10" class="py-0 mt-2">
            <!-- <TextAreaInput
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="remark"
                          placeholder="Enter Remark"
              :rules="[
              validateRules.required(
                remark,
                'Remark'
              ),
              ]"
                          v-model="remark"
                      ></TextAreaInput> -->
            <label
              class="font-size-16 font-weight-500 required ml-2"
              for="asset_type"
              >Remark</label
            >
            <v-textarea
              class="required-field"
              dense
              filled
              label="Remark"
              solo
              flat
              row-height="20"
              auto-grow
              color="cyan"
              v-model.trim="remark"
              :rules="[
                validateRules.required(remark, 'Remark'),
                validateRules.minLength(remark, 'Remark', 1),
                validateRules.maxLength(remark, 'Remark', 1024),
              ]"
              v-on:keypress="(e) => manageLimitdescr(e)"
              v-on:paste="(e) => onPaste(e)"
            ></v-textarea>
            <v-col md="12" class="text-right"
              >{{ remark ? remark.length : 0 }}/200
            </v-col>
            <!--   <v-col md="12" class="text--red">
              <div style="font-size: 14px">
                <b>{{ errorMessage }}</b>
              </div>
            </v-col> -->
          </v-col>
          <v-col v-if="approveNote" md="12" class="pb-0 my-auto">
            <em>Note: {{ approveNote }}</em>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        depressed
        tile
        :disabled="approveButton"
        v-on:click="$emit('close', true)"
      >
        No, Close
      </v-btn>
      <slot name="extra-btn"></slot>
      <v-btn
        class="white--text"
        :loading="approveButton"
        :disabled="
          approveButton || (remark && remark.length && remark.length == 0)
        "
        depressed
        color="green lighten-1"
        tile
        v-on:click="approveSubmit()"
      >
        Yes! Please
      </v-btn>
    </template>
  </Dialog>
</template>

<!-- <style>
div.required-field.v-text-field.v-text-field--solo div.v-input__slot {
  border: 1px solid red !important;
}
</style> -->

<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
//import TextAreaInput from "@/view/pages/TextAreaInput";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  name: "delete-template",
  title: "Delete Template",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    statusText: {
      type: String,
      default: "Mark as Faulty",
    },
    statusValue: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: "mark_as_approved",
    },
    uuid: {
      type: String,
      default: null,
    },
    approveType: {
      type: String,
      default: null,
    },
    approveNote: {
      type: String,
      default: null,
    },
    approveDialog: {
      type: Boolean,
      default: false,
    },
    updateStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: false,
      remark: null,
      approveButton: false,
      errorMessage: null,
    };
  },
  components: {
    Dialog,
    // TextAreaInput
  },
  methods: {
    manageLimitdescr(e) {
      this.errorMessage = null;
      if (this.remark && this.remark.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.remark = trimValue;
        e.preventDefault();
      }
    },
    approveSubmit() {
      const _this = this;
      if (this.updateStatus) {
        const formErrors = this.validateForm(this.$refs["approveButtonForm"]);
        _this.$refs.approveButtonForm.validate();
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }
        const payload = {
          action: _this.status,
          equipment: [_this.uuid],
          remark: _this.remark ? _this.remark : null,
          status: _this.statusValue ? _this.statusValue : 0,
        };
        _this.approveButton = true;
        ApiService.patch(`${_this.approveType}/status`, payload)
          .then(() => {
            _this.errorMessage = null;
            _this.$emit("success");
            _this.$emit("close", true);
            _this.$store.dispatch(SET_LOCAL_DB);
            _this.$store.commit(SET_MESSAGE, [
              {
                model: true,
                message: `Success ! ${_this.type} status has been saved.`,
              },
            ]);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.approveButton = false;
          });
      } else {
        _this.$emit("success");
      }
    },
  },
};
</script>
